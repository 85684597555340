@use './mixins' as mixin;
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

html {
   font-family: 'PT Sans', sans-serif;
   background-color: #231f1e;
   color: #ffffff;
}

#root {
   max-width: 100vw;
   overflow-x: hidden;
}

ul,
li {
   list-style-type: none;
}

a {
   color: inherit;
   text-decoration: none;
}

.container-splash {
   position: fixed;
   top: 0;
   left: 0;

   .splash {
      @include mixin.flex();
      height: 100vh;
      width: 100vw;
      animation: appear 3000ms forwards;

      img {
         max-width: 15vw;
      }

      @media screen and (max-width: 768px) {
         img {
            max-width: 40vw;
         }
      }

      @keyframes appear {
         0% {
            opacity: 0;
            transform: scale(0.5);
         }
         93% {
            opacity: 1;
            transform: scale(2.5);
         }
         100% {
            opacity: 0;
            transform: scale(0.1);
         }
      }
   }
}

.container {
   .row {
      @include mixin.flex($align: stretch);

      &:not(:first-child) {
         transform: translateY(200px);
         opacity: 0;
         transition: all 300ms ease;
      }

      .photo {
         width: 100%;
         outline: none;

         span {
            height: 100%;
         }

         img {
            width: 100%;
            height: 100%;
         }
      }

      &.row-upper-0 .photo-0 {
         transform: scaleX(-1) !important;
      }

      &.row-in-page {
         transform: translateY(0) !important;
         opacity: 1 !important;
      }
   }

   .video-container {
      @include mixin.flex();

      video {
         width: 100%;
         height: 100%;
      }
   }
}

.footer {
   @include mixin.flex(
      $justify: flex-start,
      $align: flex-start,
      $gap: 40px,
      $wrap: true
   );
   margin: 120px 0 70px 0;
   padding: 0 6vw;

   .footer-el {
      width: calc(50% - 40px);

      .title {
         display: block;
         font-size: 30px;
         text-transform: uppercase;
         margin-bottom: 20px;
      }

      li {
         margin-bottom: 8px;
      }

      &:last-child li:last-child {
         a:hover {
            border-bottom: 1px solid #ffffff;
         }

         span {
            margin-right: 6px;
         }
      }
   }

   @media only screen and (max-width: 768px) {
      .footer-el {
         width: calc(100%);
      }
   }
}
